import { AxiosInstance } from 'axios';
import { AI_ASSISTANT_OPTION } from 'pages/OneAnswer/oneAnswer.constant';
import { TApiResponse } from 'types/common';
import {
  CreateWhiteboardResponse,
  GetSubjectIssueListPayload,
  GetSubjectIssueListResponse,
  SubjectIssue,
} from 'types/oneAnswer';

type AiApiResponse<T> = Promise<{
  msg?: string;
  data?: T;
  err: number;
  from: string;
  status: string;
}>;

export const oneAnswerApi = (axios: AxiosInstance, apiDomain: string) => ({
  /**
   *
   * @param params skip: number; limit: number;
   * @returns 可接單列表
   */
  getSubjectIssueList: (
    params: GetSubjectIssueListPayload,
  ): TApiResponse<GetSubjectIssueListResponse> => {
    return axios.get(`${apiDomain}/subjectIssue/getSubjectIssueList`, {
      params,
    });
  },
  /**
   * @param channelURL 問題頻道
   * @returns 問題資訊
   */
  getSubjectIssueByChannelURL: (
    channelURL: string,
  ): TApiResponse<SubjectIssue> => {
    return axios.get(`${apiDomain}/subjectIssue/getSubjectIssue/${channelURL}`);
  },
  /**
   * @param id subjectIssue id
   * @returns 接單結果
   */
  acceptIssue: (id: string): TApiResponse<{ channelURL: string }> => {
    return axios.post(`${apiDomain}/subjectIssue/acceptIssue/${id}`);
  },
  /**
   * @param channelURL 問題頻道
   * @returns 教師更新問題狀態
   */
  updateSubjectIssueStatus: (channelURL: string): TApiResponse<any> => {
    return axios.patch(
      `${apiDomain}/subjectIssue/teacherUpdateSubjectIssueStatus/${channelURL}`,
    );
  },
  /**
   * @param channelURL 問題頻道
   * @returns 白板
   */
  createWhiteboard: (
    channelURL: string,
  ): TApiResponse<CreateWhiteboardResponse> => {
    return axios.post(
      `${apiDomain}/subjectIssue/createWhiteboard/${channelURL}`,
    );
  },
  /**
   *
   */
  uploadVideoToSendbird: (
    channelURL: string,
    video: Blob,
  ): TApiResponse<any> => {
    const formData = new FormData();
    const currentTime = new Date().getTime();
    formData.append('file', new File([video], `${currentTime}.webm`));
    return axios.post(
      `${apiDomain}/subjectIssue/uploadVideoToSendbird/${channelURL}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      },
    );
  },
  /**
   * @param channelURL 問題頻道
   * @returns 老師將該問題棄單、回到可接單列表
   */
  postAbandonIssue: (channelURL: string): TApiResponse<any> => {
    return axios.post(
      `${apiDomain}/subjectIssue/teacherAbandonSubjectIssue/${channelURL}`,
    );
  },
  /**
   *
   * @param channelURL 問題頻道
   * @param data ai助手參數: chatId(繼續解釋/重新產生才要帶), option, question(產生問題才要帶)
   * @returns ai助手回覆: chatId, content
   */
  aiAssistant: (
    channelURL: string,
    data: {
      chatId: string;
      option?: AI_ASSISTANT_OPTION;
      question: string;
    },
  ): AiApiResponse<{
    chatId: string;
    content: string;
  }> => {
    return axios.post(`${apiDomain}/ai/aiAssistant/${channelURL}`, data);
  },
});
