import { Navigate, useRoutes, RouteObject } from 'react-router-dom';
import { LogoOnlyLayout, Logo } from '@onedesign/ui';
import DashboardLayout from 'layouts/dashboard';
import { lazyLoad } from './LazyLoad';
import { UNIVERSAL_ROUTE, PATH } from './routeConfig';
import { scheduleRoutes } from './schedule';
import { profileRoutes } from './profile';
import { courseRoutes } from './course';
import { notificationRoutes } from './notification';
import { teacherApplicationRoutes } from './teacherApplication';
import { crossSubjectApplicationRoutes } from './crossSubjectApplication';
import { oneAnswerRoutes } from './oneAnswer';
import { teacherTerminatedContractRoutes } from './teacherTerminatedContract';
import { requestSignEmploymentContractRoutes } from './requestSignEmploymentContract';

export const Routes = () => {
  const routes: RouteObject[] = [
    {
      path: UNIVERSAL_ROUTE.ROOT,
      element: <DashboardLayout />,
      children: [
        ...scheduleRoutes,
        ...profileRoutes,
        ...courseRoutes,
        ...notificationRoutes,
        ...teacherApplicationRoutes,
        ...crossSubjectApplicationRoutes,
        ...oneAnswerRoutes,
        ...teacherTerminatedContractRoutes,
        ...requestSignEmploymentContractRoutes,
      ],
    },
    {
      path: UNIVERSAL_ROUTE.LOGIN,
      element: lazyLoad(() => import('pages/Login')),
    },
    {
      path: UNIVERSAL_ROUTE.REGISTER,
      element: lazyLoad(() => import('pages/Register')),
    },
    {
      path: UNIVERSAL_ROUTE.OTHER,
      element: <LogoOnlyLayout logo={<Logo />} />,
      children: [
        {
          path: UNIVERSAL_ROUTE.OTHER,
          element: <Navigate to={PATH.NOT_FOUND} replace />,
        },
        {
          path: UNIVERSAL_ROUTE.NOT_FOUND,
          element: lazyLoad(() => import('pages/NotFound')),
        },
      ],
    },
  ];

  return useRoutes(routes);
};
