import { t } from 'utils/i18n';
export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
  THIRD = 'third',
}

export enum Degree {
  BACHELOR_DEGREE = 'bachelor_degree',
  MASTER_DEGREE = 'master_degree',
  DOCTORATE = 'doctorate',
}

export enum Subject {
  MANDARIN = 'mandarin',
  ENGLISH = 'english',
  MATH = 'math',
  SOCIAL_SCIENCE = 'social_science',
  NATURAL_SCIENCE = 'natural_science',
  OTHER = 'other',
}

export enum Course {
  ELEMENTARY_SCHOOL_MANDARIN = 'elementary_school_mandarin',
  MANDARIN_PHONETIC_SYMBOLS = 'mandarin_phonetic_symbols',
  ESSAY_WRITING = 'essay_writing',
  JUNIOR_HIGH_SCHOOL_MANDARIN = 'junior_high_school_mandarin',
  HIGH_SCHOOL_MANDARIN = 'high_school_mandarin',
  ENGLISH_PRONUNCIATION_A1 = 'english_pronunciation_a1',
  ENGLISH_GRAMMAR_A1 = 'english_grammar_a1',
  ENGLISH_GRAMMAR_A2 = 'english_grammar_a2',
  ENGLISH_READING_A2 = 'english_reading_a2',
  ENGLISH_READING_B2 = 'english_reading_b2',
  ENGLISH_SPEAKING_A2 = 'english_speaking_a2',
  ENGLISH_WRITING_A2 = 'english_writing_a2',
  ENGLISH_WRITING_B1 = 'english_writing_b1',
  ENGLISH_PROFICIENCY_TEST_B1 = 'english_proficiency_test_b1',
  ELEMENTARY_SCHOOL_MATH = 'elementary_school_math',
  JUNIOR_HIGH_SCHOOL_MATH = 'junior_high_school_math',
  HIGH_SCHOOL_MATH = 'high_school_math',
  ELEMENTARY_SCHOOL_SOCIAL_SCIENCE = 'elementary_school_social_science',
  JUNIOR_HIGH_SCHOOL_GEOGRAPHY = 'junior_high_school_geography',
  JUNIOR_HIGH_SCHOOL_HISTORY = 'junior_high_school_history',
  JUNIOR_HIGH_SCHOOL_CIVICS = 'junior_high_school_civics',
  HIGH_SCHOOL_GEOGRAPHY = 'high_school_geography',
  HIGH_SCHOOL_HISTORY = 'high_school_history',
  HIGH_SCHOOL_CIVICS = 'high_school_civics',
  ELEMENTARY_SCHOOL_SCIENCE = 'elementary_school_science',
  ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT = 'elementary_school_science_experiment',
  JUNIOR_HIGH_SCHOOL_BIOLOGY = 'junior_high_school_biology',
  JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY = 'junior_high_school_physics_and_chemistry',
  JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE = 'junior_high_school_earth_science',
  HIGH_SCHOOL_EARTH_SCIENCE = 'high_school_earth_science',
  HIGH_SCHOOL_PHYSICS = 'high_school_physics',
  HIGH_SCHOOL_CHEMISTRY = 'high_school_chemistry',
  HIGH_SCHOOL_BIOLOGY = 'high_school_biology',
  MANDARIN_AS_A_FOREIGN_LANGUAGE = 'mandarin_as_a_foreign_language',
}
export enum AdditionalCourseMultiInterview {
  ESSAY_WRITING_BRONZE = 'essay_writing_bronze',
  ESSAY_WRITING_SILVER = 'essay_writing_silver',
  ESSAY_WRITING_GOLD = 'essay_writing_gold',
}

export enum TeachingEnvironment {
  ONLINE_TEACHING = 'online_teaching',
  IN_PERSON_TUTORING = 'in_person_tutoring',
  CRAM_SCHOOL = 'cram_school',
  SCHOOL = 'school',
  OTHER = 'other',
}

export enum YearsOfExperience {
  LESS_THAN_1_YEAR = 'less_than_1_year',
  FROM_1_2_YEARS = 'from_1_2_years',
  MORE_THAN_3_YEARS = 'more_than_3_years',
}

export enum EmploymentStatus {
  UNEMPLOYED = 'unemployed',
  FULL_TIME = 'full_time',
  STUDENT = 'student',
  PART_TIME = 'part_time',
}

export enum WeeklyAvailableTeachingHours {
  LESS_THAN_10_HOURS = 'less_than_10_hours',
  FROM_11_20_HOURS = 'from_11_20_hours',
  FROM_21_30_HOURS = 'from_21_30_hours',
  FROM_31_40_HOURS = 'from_31_40_hours',
  MORE_THAN_41_HOURS = 'more_than_41_hours',
}

export enum StaffType {
  IDLE = 'idle',
  INSIDE = 'inside',
  OUTSIDE = 'outside',
}

export enum TeacherStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  PAUSED = 'paused',
  UNVERIFIED = 'unverified',
}

export enum MessageType {
  COURSE_REMINDER = 'courseReminder',
  COURSE_SCHEDULE_REMINDER = 'courseScheduleReminder',
  COURSE_SCHEDULE_NOTICE = 'courseScheduleNotice',
  COURSE_CHANGE = 'courseChange',
  COURSE_SCHEDULE_REQUEST = 'courseScheduleRequest',
  TEXT = 'text',
  INTERACTIVE = 'interactive',
  COURSE_CANCEL = 'courseCancel',
}

export enum ReadStatus {
  READ = 'read',
  UNREAD = 'unread',
}

export enum InteractiveStatus {
  UNUSED = 'unused',
  CANCEL = 'cancel',
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  SCHEDULED = 'scheduled',
  WAIT_FEEDBACK = 'waitFeedback',
  INVALID = 'invalid',
  REJECT = 'reject',
}

export enum InteractiveAccept {
  UNUSED = 'unused',
  CHOOSE = 'choose',
  ACCEPT = 'accept',
  UNACCEPT = 'unaccept',
}

export enum InterviewStatus {
  INITIAL_INTERVIEW = 'initial_interview',
  EDITING = 'editing',
  RE_INTERVIEW = 're_interview',
}

export enum ReviewStatus {
  UNREVIEWED = 'unreviewed',
  REVIEWING = 'reviewing',
  PASSED = 'passed',
  FAILED = 'failed',
  RE_INTERVIEW = 're_interview',
}

export enum TeacherType {
  UNSIGNED = 'unsigned',
  SIGNED = 'signed',
  OFFICIAL = 'official',
}
export function getCourseLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      value: Course.ELEMENTARY_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.elementarySchoolMandarin',
        'Elementary School Mandarin',
      ),
    },
    {
      value: Course.MANDARIN_PHONETIC_SYMBOLS,
      label: t(
        'teacherApplication.course.mandarinPhoneticSymbols',
        'Mandarin Phonetic Symbols',
      ),
    },
    {
      value: Course.ESSAY_WRITING,
      label: t('teacherApplication.course.essayWriting', 'Essay Writing'),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.juniorHighSchoolMandarin',
        'Junior High School Mandarin',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.highSchoolMandarin',
        'High School Mandarin',
      ),
    },
    // {
    //   value: Course.ENGLISH_PRONUNCIATION_A1,
    //   label: t(
    //     'teacherApplication.course.englishPronunciationA1',
    //     'English Pronunciation A1',
    //   ),
    // },
    {
      value: Course.ENGLISH_GRAMMAR_A1,
      label: t(
        'teacherApplication.course.englishGrammarA1',
        'English Grammar A1',
      ),
    },
    {
      value: Course.ENGLISH_GRAMMAR_A2,
      label: t(
        'teacherApplication.course.englishGrammarA2',
        'English Grammar A2',
      ),
    },
    {
      value: Course.ENGLISH_READING_A2,
      label: t(
        'teacherApplication.course.englishReadingA2',
        'English Reading A2',
      ),
    },
    {
      value: Course.ENGLISH_READING_B2,
      label: t(
        'teacherApplication.course.englishReadingB2',
        'English Reading B2',
      ),
    },
    {
      value: Course.ENGLISH_SPEAKING_A2,
      label: t(
        'teacherApplication.course.englishSpeakingA2',
        'English Speaking A2',
      ),
    },
    {
      value: Course.ENGLISH_WRITING_A2,
      label: t(
        'teacherApplication.course.englishWritingA2',
        'English Writing A2',
      ),
    },
    // {
    //   value: Course.ENGLISH_WRITING_B1,
    //   label: t(
    //     'teacherApplication.course.englishWritingB1',
    //     'English Writing B1',
    //   ),
    // },
    {
      value: Course.ENGLISH_PROFICIENCY_TEST_B1,
      label: t(
        'teacherApplication.course.englishProficiencyTestB1',
        'English Proficiency Test B1',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_MATH,
      label: t(
        'teacherApplication.course.elementarySchoolMath',
        'Elementary School Math',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_MATH,
      label: t(
        'teacherApplication.course.juniorHighSchoolMath',
        'Junior High School Math',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_MATH,
      label: t('teacherApplication.course.highSchoolMath', 'High School Math'),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
      label: t(
        'teacherApplication.course.elementarySchoolSocialScience',
        'Elementary School Social Science',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
      label: t(
        'teacherApplication.course.juniorHighSchoolGeography',
        'Junior High School Geography',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
      label: t(
        'teacherApplication.course.juniorHighSchoolHistory',
        'Junior High School History',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
      label: t(
        'teacherApplication.course.juniorHighSchoolCivics',
        'Junior High School Civics',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_GEOGRAPHY,
      label: t(
        'teacherApplication.course.highSchoolGeography',
        'High School Geography',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_HISTORY,
      label: t(
        'teacherApplication.course.highSchoolHistory',
        'High School History',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_CIVICS,
      label: t(
        'teacherApplication.course.highSchoolCivics',
        'High School Civics',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SCIENCE,
      label: t(
        'teacherApplication.course.elementarySchoolScience',
        'Elementary School Science',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
      label: t(
        'teacherApplication.course.elementarySchoolScienceExperiment',
        'Elementary School Science Experiment',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
      label: t(
        'teacherApplication.course.juniorHighSchoolBiology',
        'Junior High School Biology',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
      label: t(
        'teacherApplication.course.juniorHighSchoolPhysicsAndChemistry',
        'Junior High School Physics and Chemistry',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
      label: t(
        'teacherApplication.course.juniorHighSchoolEarthScience',
        'Junior High School Earth Science',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_EARTH_SCIENCE,
      label: t(
        'teacherApplication.course.highSchoolEarthScience',
        'High School Earth Science',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_PHYSICS,
      label: t(
        'teacherApplication.course.highSchoolPhysics',
        'High School Physics',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_CHEMISTRY,
      label: t(
        'teacherApplication.course.highSchoolChemistry',
        'High School Chemistry',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_BIOLOGY,
      label: t(
        'teacherApplication.course.highSchoolBiology',
        'High School Biology',
      ),
    },
    {
      value: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
      label: t(
        'teacherApplication.course.mandarinAsForeignLanguage',
        'Mandarin as a Foreign Language',
      ),
    },
  ];
}
export function getCourseLabelFull() {
  return [
    {
      value: Course.ELEMENTARY_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.elementarySchoolMandarin',
        'Elementary School Mandarin',
      ),
    },
    {
      value: Course.MANDARIN_PHONETIC_SYMBOLS,
      label: t(
        'teacherApplication.course.mandarinPhoneticSymbols',
        'Mandarin Phonetic Symbols',
      ),
    },
    {
      value: Course.ESSAY_WRITING,
      label: t('teacherApplication.course.essayWriting', 'Essay Writing'),
    },
    {
      value: AdditionalCourseMultiInterview.ESSAY_WRITING_BRONZE,
      label: t('teacherApplication.course.essayWritingBronze', '作文青銅'),
    },
    {
      value: AdditionalCourseMultiInterview.ESSAY_WRITING_SILVER,
      label: t('teacherApplication.course.essayWritingSilver', '作文白銀'),
    },
    {
      value: AdditionalCourseMultiInterview.ESSAY_WRITING_GOLD,
      label: t('teacherApplication.course.essayWritingGold', '作文黃金'),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.juniorHighSchoolMandarin',
        'Junior High School Mandarin',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_MANDARIN,
      label: t(
        'teacherApplication.course.highSchoolMandarin',
        'High School Mandarin',
      ),
    },
    {
      value: Course.ENGLISH_PRONUNCIATION_A1,
      label: t(
        'teacherApplication.course.englishPronunciationA1',
        'English Pronunciation A1',
      ),
    },
    {
      value: Course.ENGLISH_GRAMMAR_A1,
      label: t(
        'teacherApplication.course.englishGrammarA1',
        'English Grammar A1',
      ),
    },
    {
      value: Course.ENGLISH_GRAMMAR_A2,
      label: t(
        'teacherApplication.course.englishGrammarA2',
        'English Grammar A2',
      ),
    },
    {
      value: Course.ENGLISH_READING_A2,
      label: t(
        'teacherApplication.course.englishReadingA2',
        'English Reading A2',
      ),
    },
    {
      value: Course.ENGLISH_READING_B2,
      label: t(
        'teacherApplication.course.englishReadingB2',
        'English Reading B2',
      ),
    },
    {
      value: Course.ENGLISH_SPEAKING_A2,
      label: t(
        'teacherApplication.course.englishSpeakingA2',
        'English Speaking A2',
      ),
    },
    {
      value: Course.ENGLISH_WRITING_A2,
      label: t(
        'teacherApplication.course.englishWritingA2',
        'English Writing A2',
      ),
    },
    {
      value: Course.ENGLISH_WRITING_B1,
      label: t(
        'teacherApplication.course.englishWritingB1',
        'English Writing B1',
      ),
    },
    {
      value: Course.ENGLISH_PROFICIENCY_TEST_B1,
      label: t(
        'teacherApplication.course.englishProficiencyTestB1',
        'English Proficiency Test B1',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_MATH,
      label: t(
        'teacherApplication.course.elementarySchoolMath',
        'Elementary School Math',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_MATH,
      label: t(
        'teacherApplication.course.juniorHighSchoolMath',
        'Junior High School Math',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_MATH,
      label: t('teacherApplication.course.highSchoolMath', 'High School Math'),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
      label: t(
        'teacherApplication.course.elementarySchoolSocialScience',
        'Elementary School Social Science',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
      label: t(
        'teacherApplication.course.juniorHighSchoolGeography',
        'Junior High School Geography',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
      label: t(
        'teacherApplication.course.juniorHighSchoolHistory',
        'Junior High School History',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
      label: t(
        'teacherApplication.course.juniorHighSchoolCivics',
        'Junior High School Civics',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_GEOGRAPHY,
      label: t(
        'teacherApplication.course.highSchoolGeography',
        'High School Geography',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_HISTORY,
      label: t(
        'teacherApplication.course.highSchoolHistory',
        'High School History',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_CIVICS,
      label: t(
        'teacherApplication.course.highSchoolCivics',
        'High School Civics',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SCIENCE,
      label: t(
        'teacherApplication.course.elementarySchoolScience',
        'Elementary School Science',
      ),
    },
    {
      value: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
      label: t(
        'teacherApplication.course.elementarySchoolScienceExperiment',
        'Elementary School Science Experiment',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
      label: t(
        'teacherApplication.course.juniorHighSchoolBiology',
        'Junior High School Biology',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
      label: t(
        'teacherApplication.course.juniorHighSchoolPhysicsAndChemistry',
        'Junior High School Physics and Chemistry',
      ),
    },
    {
      value: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
      label: t(
        'teacherApplication.course.juniorHighSchoolEarthScience',
        'Junior High School Earth Science',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_EARTH_SCIENCE,
      label: t(
        'teacherApplication.course.highSchoolEarthScience',
        'High School Earth Science',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_PHYSICS,
      label: t(
        'teacherApplication.course.highSchoolPhysics',
        'High School Physics',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_CHEMISTRY,
      label: t(
        'teacherApplication.course.highSchoolChemistry',
        'High School Chemistry',
      ),
    },
    {
      value: Course.HIGH_SCHOOL_BIOLOGY,
      label: t(
        'teacherApplication.course.highSchoolBiology',
        'High School Biology',
      ),
    },
    {
      value: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
      label: t(
        'teacherApplication.course.mandarinAsForeignLanguage',
        'Mandarin as a Foreign Language',
      ),
    },
  ];
}

export enum MonthlySalaryExpectation {
  NT_30000 = 'nt_30000',
  NT_30000_40000 = 'nt_30000_40000',
  NT_40000_50000 = 'nt_40000_50000',
  NT_50000_60000 = 'nt_50000_60000',
  NT_60000 = 'nt_60000',
}

export function getWorkingStatusLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      label: t('teacherApplication.workingStatus.unemployed', 'Unemployed'),
      value: EmploymentStatus.UNEMPLOYED,
    },
    {
      label: t('teacherApplication.workingStatus.fulltime', 'Full-time'),
      value: EmploymentStatus.FULL_TIME,
    },
    {
      label: t('teacherApplication.workingStatus.student', 'Student'),
      value: EmploymentStatus.STUDENT,
    },
    {
      label: t('teacherApplication.workingStatus.partTime', 'Part-time'),
      value: EmploymentStatus.PART_TIME,
    },
  ];
}

export function getAvailableHoursPerWeekLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      value: WeeklyAvailableTeachingHours.LESS_THAN_10_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.lessThanTenHours',
        'Less than 10 hours',
      ),
    },
    {
      value: WeeklyAvailableTeachingHours.FROM_11_20_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.elevenToTwentyHours',
        '11-20 hours',
      ),
    },
    {
      value: WeeklyAvailableTeachingHours.FROM_21_30_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.twentyOneToThirtyHours',
        '21-30 hours',
      ),
    },
    {
      value: WeeklyAvailableTeachingHours.FROM_31_40_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.thirtyOneToFortyHours',
        '31-40 hours',
      ),
    },
    {
      value: WeeklyAvailableTeachingHours.MORE_THAN_41_HOURS,
      label: t(
        'teacherApplication.availableHoursPerWeek.moreThanFortyOneHours',
        'More than 41 hours',
      ),
    },
  ];
}

export function getDegreeLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      label: t('teacherApplication.degree.bachelor', 'Bachelor’s Degree'),
      value: Degree.BACHELOR_DEGREE,
    },
    {
      label: t('teacherApplication.degree.master', 'Master’s Degree'),
      value: Degree.MASTER_DEGREE,
    },
    {
      label: t('teacherApplication.degree.doctorate', 'Doctorate'),
      value: Degree.DOCTORATE,
    },
  ];
}

export function getMonthlySalaryExpectationLabel(
  t: (key: string, defaultValue: string, params?: any | null) => string,
) {
  return [
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.nt_30000',
        '低於 NT$ 30,000',
      ),
      value: MonthlySalaryExpectation.NT_30000,
    },
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.nt_30000_40000',
        'NT$ 30,000 - 40,000',
      ),
      value: MonthlySalaryExpectation.NT_30000_40000,
    },
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.nt_40000_50000',
        'NT$ 40,000 - 50,000',
      ),
      value: MonthlySalaryExpectation.NT_40000_50000,
    },
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.nt_50000_60000',
        'NT$ 50,000 - 60,000',
      ),
      value: MonthlySalaryExpectation.NT_50000_60000,
    },
    {
      label: t(
        'teacherApplication.monthlySalaryExpectation.nt_60000',
        '高於 NT$ 60,000',
      ),
      value: MonthlySalaryExpectation.NT_60000,
    },
  ];
}

export enum TRCApiStatus {
  SUCCESS = 'SUCCESS',
}

export enum TRCApiErrorCode {
  SUCCESS = 0,
}

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const getSubjectCourseConstant = () => {
  return [
    {
      value: Subject.MANDARIN,
      label: t('teacherApplication.subject.mandarin', 'Mandarin'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.elementarySchoolMandarin',
            'Elementary School Mandarin',
          ),
        },
        {
          value: Course.MANDARIN_PHONETIC_SYMBOLS,
          label: t(
            'teacherApplication.course.mandarinPhoneticSymbols',
            'Mandarin Phonetic Symbols',
          ),
        },
        {
          value: Course.ESSAY_WRITING,
          label: t('teacherApplication.course.essayWriting', 'Essay Writing'),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.juniorHighSchoolMandarin',
            'Junior High School Mandarin',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.highSchoolMandarin',
            'High School Mandarin',
          ),
        },
      ],
    },
    {
      value: Subject.ENGLISH,
      label: t('teacherApplication.subject.english', 'English'),
      courses: [
        // {
        //   value: Course.ENGLISH_PRONUNCIATION_A1,
        //   label: t(
        //     'teacherApplication.course.englishPronunciationA1',
        //     'English Pronunciation A1',
        //   ),
        // },
        {
          value: Course.ENGLISH_GRAMMAR_A1,
          label: t(
            'teacherApplication.course.englishGrammarA1',
            'English Grammar A1',
          ),
        },
        {
          value: Course.ENGLISH_GRAMMAR_A2,
          label: t(
            'teacherApplication.course.englishGrammarA2',
            'English Grammar A2',
          ),
        },
        {
          value: Course.ENGLISH_READING_A2,
          label: t(
            'teacherApplication.course.englishReadingA2',
            'English Reading A2',
          ),
        },
        {
          value: Course.ENGLISH_READING_B2,
          label: t(
            'teacherApplication.course.englishReadingB2',
            'English Reading B2',
          ),
        },
        {
          value: Course.ENGLISH_SPEAKING_A2,
          label: t(
            'teacherApplication.course.englishSpeakingA2',
            'English Speaking A2',
          ),
        },
        {
          value: Course.ENGLISH_WRITING_A2,
          label: t(
            'teacherApplication.course.englishWritingA2',
            'English Writing A2',
          ),
        },
        // {
        //   value: Course.ENGLISH_WRITING_B1,
        //   label: t(
        //     'teacherApplication.course.englishWritingB1',
        //     'English Writing B1',
        //   ),
        // },
        {
          value: Course.ENGLISH_PROFICIENCY_TEST_B1,
          label: t(
            'teacherApplication.course.englishProficiencyTestB1',
            'English Proficiency Test B1',
          ),
        },
      ],
    },
    {
      value: Subject.MATH,
      label: t('teacherApplication.subject.math', 'Math'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.elementarySchoolMath',
            'Elementary School Math',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.juniorHighSchoolMath',
            'Junior High School Math',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.highSchoolMath',
            'High School Math',
          ),
        },
      ],
    },
    {
      value: Subject.SOCIAL_SCIENCE,
      label: t('teacherApplication.subject.socialScience', 'Social Science'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
          label: t(
            'teacherApplication.course.elementarySchoolSocialScience',
            'Elementary School Social Science',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
          label: t(
            'teacherApplication.course.juniorHighSchoolGeography',
            'Junior High School Geography',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
          label: t(
            'teacherApplication.course.juniorHighSchoolHistory',
            'Junior High School History',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
          label: t(
            'teacherApplication.course.juniorHighSchoolCivics',
            'Junior High School Civics',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_GEOGRAPHY,
          label: t(
            'teacherApplication.course.highSchoolGeography',
            'High School Geography',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_HISTORY,
          label: t(
            'teacherApplication.course.highSchoolHistory',
            'High School History',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_CIVICS,
          label: t(
            'teacherApplication.course.highSchoolCivics',
            'High School Civics',
          ),
        },
      ],
    },
    {
      value: Subject.NATURAL_SCIENCE,
      label: t('teacherApplication.subject.naturalScience', 'Natural Science'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_SCIENCE,
          label: t(
            'teacherApplication.course.elementarySchoolScience',
            'Elementary School Science',
          ),
        },
        {
          value: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
          label: t(
            'teacherApplication.course.elementarySchoolScienceExperiment',
            'Elementary School Science Experiment',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
          label: t(
            'teacherApplication.course.juniorHighSchoolBiology',
            'Junior High School Biology',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
          label: t(
            'teacherApplication.course.juniorHighSchoolPhysicsAndChemistry',
            'Junior High School Physics and Chemistry',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
          label: t(
            'teacherApplication.course.juniorHighSchoolEarthScience',
            'Junior High School Earth Science',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_EARTH_SCIENCE,
          label: t(
            'teacherApplication.course.highSchoolEarthScience',
            'High School Earth Science',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_PHYSICS,
          label: t(
            'teacherApplication.course.highSchoolPhysics',
            'High School Physics',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_CHEMISTRY,
          label: t(
            'teacherApplication.course.highSchoolChemistry',
            'High School Chemistry',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_BIOLOGY,
          label: t(
            'teacherApplication.course.highSchoolBiology',
            'High School Biology',
          ),
        },
      ],
    },
    {
      value: Subject.OTHER,
      label: t('teacherApplication.subject.other', 'Other'),
      courses: [
        {
          value: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
          label: t(
            'teacherApplication.course.mandarinAsForeignLanguage',
            'Mandarin as a Foreign Language',
          ),
        },
      ],
    },
  ];
};
export const getSubjectCourseConstantForMultiInterview = () => {
  return [
    {
      value: Subject.MANDARIN,
      label: t('teacherApplication.subject.mandarin', 'Mandarin'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.elementarySchoolMandarin',
            'Elementary School Mandarin',
          ),
        },
        {
          value: Course.MANDARIN_PHONETIC_SYMBOLS,
          label: t(
            'teacherApplication.course.mandarinPhoneticSymbols',
            'Mandarin Phonetic Symbols',
          ),
        },
        {
          value: AdditionalCourseMultiInterview.ESSAY_WRITING_BRONZE,
          label: t('teacherApplication.course.essayWritingBronze', '作文青銅'),
        },
        {
          value: AdditionalCourseMultiInterview.ESSAY_WRITING_SILVER,
          label: t('teacherApplication.course.essayWritingSilver', '作文白銀'),
        },
        {
          value: AdditionalCourseMultiInterview.ESSAY_WRITING_GOLD,
          label: t('teacherApplication.course.essayWritingGold', '作文黃金'),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.juniorHighSchoolMandarin',
            'Junior High School Mandarin',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_MANDARIN,
          label: t(
            'teacherApplication.course.highSchoolMandarin',
            'High School Mandarin',
          ),
        },
      ],
    },
    {
      value: Subject.ENGLISH,
      label: t('teacherApplication.subject.english', 'English'),
      courses: [
        // {
        //   value: Course.ENGLISH_PRONUNCIATION_A1,
        //   label: t(
        //     'teacherApplication.course.englishPronunciationA1',
        //     'English Pronunciation A1',
        //   ),
        // },
        {
          value: Course.ENGLISH_GRAMMAR_A1,
          label: t(
            'teacherApplication.course.englishGrammarA1',
            'English Grammar A1',
          ),
        },
        {
          value: Course.ENGLISH_GRAMMAR_A2,
          label: t(
            'teacherApplication.course.englishGrammarA2',
            'English Grammar A2',
          ),
        },
        {
          value: Course.ENGLISH_READING_A2,
          label: t(
            'teacherApplication.course.englishReadingA2',
            'English Reading A2',
          ),
        },
        {
          value: Course.ENGLISH_READING_B2,
          label: t(
            'teacherApplication.course.englishReadingB2',
            'English Reading B2',
          ),
        },
        {
          value: Course.ENGLISH_SPEAKING_A2,
          label: t(
            'teacherApplication.course.englishSpeakingA2',
            'English Speaking A2',
          ),
        },
        {
          value: Course.ENGLISH_WRITING_A2,
          label: t(
            'teacherApplication.course.englishWritingA2',
            'English Writing A2',
          ),
        },
        // {
        //   value: Course.ENGLISH_WRITING_B1,
        //   label: t(
        //     'teacherApplication.course.englishWritingB1',
        //     'English Writing B1',
        //   ),
        // },
        {
          value: Course.ENGLISH_PROFICIENCY_TEST_B1,
          label: t(
            'teacherApplication.course.englishProficiencyTestB1',
            'English Proficiency Test B1',
          ),
        },
      ],
    },
    {
      value: Subject.MATH,
      label: t('teacherApplication.subject.math', 'Math'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.elementarySchoolMath',
            'Elementary School Math',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.juniorHighSchoolMath',
            'Junior High School Math',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_MATH,
          label: t(
            'teacherApplication.course.highSchoolMath',
            'High School Math',
          ),
        },
      ],
    },
    {
      value: Subject.SOCIAL_SCIENCE,
      label: t('teacherApplication.subject.socialScience', 'Social Science'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
          label: t(
            'teacherApplication.course.elementarySchoolSocialScience',
            'Elementary School Social Science',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
          label: t(
            'teacherApplication.course.juniorHighSchoolGeography',
            'Junior High School Geography',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
          label: t(
            'teacherApplication.course.juniorHighSchoolHistory',
            'Junior High School History',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
          label: t(
            'teacherApplication.course.juniorHighSchoolCivics',
            'Junior High School Civics',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_GEOGRAPHY,
          label: t(
            'teacherApplication.course.highSchoolGeography',
            'High School Geography',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_HISTORY,
          label: t(
            'teacherApplication.course.highSchoolHistory',
            'High School History',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_CIVICS,
          label: t(
            'teacherApplication.course.highSchoolCivics',
            'High School Civics',
          ),
        },
      ],
    },
    {
      value: Subject.NATURAL_SCIENCE,
      label: t('teacherApplication.subject.naturalScience', 'Natural Science'),
      courses: [
        {
          value: Course.ELEMENTARY_SCHOOL_SCIENCE,
          label: t(
            'teacherApplication.course.elementarySchoolScience',
            'Elementary School Science',
          ),
        },
        {
          value: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
          label: t(
            'teacherApplication.course.elementarySchoolScienceExperiment',
            'Elementary School Science Experiment',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
          label: t(
            'teacherApplication.course.juniorHighSchoolBiology',
            'Junior High School Biology',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
          label: t(
            'teacherApplication.course.juniorHighSchoolPhysicsAndChemistry',
            'Junior High School Physics and Chemistry',
          ),
        },
        {
          value: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
          label: t(
            'teacherApplication.course.juniorHighSchoolEarthScience',
            'Junior High School Earth Science',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_EARTH_SCIENCE,
          label: t(
            'teacherApplication.course.highSchoolEarthScience',
            'High School Earth Science',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_PHYSICS,
          label: t(
            'teacherApplication.course.highSchoolPhysics',
            'High School Physics',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_CHEMISTRY,
          label: t(
            'teacherApplication.course.highSchoolChemistry',
            'High School Chemistry',
          ),
        },
        {
          value: Course.HIGH_SCHOOL_BIOLOGY,
          label: t(
            'teacherApplication.course.highSchoolBiology',
            'High School Biology',
          ),
        },
      ],
    },
    {
      value: Subject.OTHER,
      label: t('teacherApplication.subject.other', 'Other'),
      courses: [
        {
          value: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
          label: t(
            'teacherApplication.course.mandarinAsForeignLanguage',
            'Mandarin as a Foreign Language',
          ),
        },
      ],
    },
  ];
};

export enum TeacherOrigin {
  UNOFFICIAL_NEW_TEACHER = 'UNOFFICIAL_NEW_TEACHER',
  OFFICIAL_NEW_TEACHER = 'OFFICIAL_NEW_TEACHER',
  OFFICIAL_OLD_TEACHER = 'OFFICIAL_OLD_TEACHER',
}

export const COURSE_BOOK_ID = [
  {
    key: Course.ELEMENTARY_SCHOOL_MANDARIN,
    value: '38bc3224dc9ca321',
  },
  {
    key: Course.MANDARIN_PHONETIC_SYMBOLS,
    value: '7654b5b96755f93b',
  },
  {
    key: Course.ESSAY_WRITING,
    value: 'dc595d419d78872c',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
    value: '24f868064c5286b9',
  },
  {
    key: Course.HIGH_SCHOOL_MANDARIN,
    value: '22bb4497296cdcf4',
  },
  // {
  //   key: Course.ENGLISH_PRONUNCIATION_A1,
  //   value: '41fb66bf4135a6f5',
  // },
  {
    key: Course.ENGLISH_GRAMMAR_A1,
    value: '282c970a694a38a6',
  },
  {
    key: Course.ENGLISH_GRAMMAR_A2,
    value: 'b8216fff8f7a1b83',
  },
  {
    key: Course.ENGLISH_READING_A2,
    value: '1743b684315c0de0',
  },
  {
    key: Course.ENGLISH_READING_B2,
    value: 'feb4f2901d9fd82d',
  },
  {
    key: Course.ENGLISH_SPEAKING_A2,
    value: '84d5560bc22fe4f0',
  },
  {
    key: Course.ENGLISH_WRITING_A2,
    value: '163340c1ddba996e',
  },
  // {
  //   key: Course.ENGLISH_WRITING_B1,
  //   value: 'a0687a563e243085',
  // },
  {
    key: Course.ENGLISH_PROFICIENCY_TEST_B1,
    value: '9f46de2414b95e79',
  },

  {
    key: Course.ELEMENTARY_SCHOOL_MATH,
    value: '6b0a75242895b740',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_MATH,
    value: 'd257b6a0bdf4ad3b',
  },
  {
    key: Course.HIGH_SCHOOL_MATH,
    value: 'd907963efd4a5224',
  },

  {
    key: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
    value: 'd4a96600bd5861bb',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
    value: '80eea8d8a3439ca0',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
    value: '48b9acf7cc9b81b1',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
    value: '3b2ef892100b8d58',
  },
  {
    key: Course.HIGH_SCHOOL_GEOGRAPHY,
    value: 'bc2b538264e6aa43',
  },
  {
    key: Course.HIGH_SCHOOL_HISTORY,
    value: 'a246aad1bcbf79b7',
  },
  {
    key: Course.HIGH_SCHOOL_CIVICS,
    value: '4672b5a91be19c61',
  },
  {
    key: Course.ELEMENTARY_SCHOOL_SCIENCE,
    value: 'c7bb25cf02476b8e',
  },
  {
    key: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
    value: 'e2420e1680a8ed67',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
    value: '422abf0510fc166c',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
    value: 'd246edb6b6f70a0a',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
    value: '2c4861c3b6195158',
  },
  {
    key: Course.HIGH_SCHOOL_EARTH_SCIENCE,
    value: '6f4c3f12dce473f0',
  },
  {
    key: Course.HIGH_SCHOOL_PHYSICS,
    value: '069ab3b084ed1ccf',
  },
  {
    key: Course.HIGH_SCHOOL_CHEMISTRY,
    value: 'd7b80404e128c241',
  },
  {
    key: Course.HIGH_SCHOOL_BIOLOGY,
    value: 'b76261e142c72ca0',
  },
  {
    key: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
    value: '2f1da6931f561157',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_BRONZE,
    value: '62d6cf9b161a5417',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_SILVER,
    value: '5545d5045a57c32e',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_GOLD,
    value: '7c3ed1fd12d9cd9a',
  },
];

export const COURSE_BOOK_INSTRUCTION_ID = [
  {
    key: Course.ELEMENTARY_SCHOOL_MANDARIN,
    value: '366fef231f676938',
  },
  {
    key: Course.MANDARIN_PHONETIC_SYMBOLS,
    value: '03571d4b9a31d0ed',
  },
  {
    key: Course.ESSAY_WRITING,
    value: 'c127a1d7bbae2aec',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_MANDARIN,
    value: '95fdd62857ede450',
  },
  {
    key: Course.HIGH_SCHOOL_MANDARIN,
    value: '987d83648ecfd670',
  },
  // {
  //   key: Course.ENGLISH_PRONUNCIATION_A1,
  //   value: '41fb66bf4135a6f5',
  // },
  {
    key: Course.ENGLISH_GRAMMAR_A1,
    value: '03c36c3de7d7aad2',
  },
  {
    key: Course.ENGLISH_GRAMMAR_A2,
    value: '66214010e07be681',
  },
  {
    key: Course.ENGLISH_READING_A2,
    value: 'f2d089e81cd2a99f',
  },
  {
    key: Course.ENGLISH_READING_B2,
    value: '87c88e471deb7734',
  },
  {
    key: Course.ENGLISH_SPEAKING_A2,
    value: 'df565eb7431911f4',
  },
  {
    key: Course.ENGLISH_WRITING_A2,
    value: 'ba58a650f237c0be',
  },
  // {
  //   key: Course.ENGLISH_WRITING_B1,
  //   value: 'a0687a563e243085',
  // },
  {
    key: Course.ENGLISH_PROFICIENCY_TEST_B1,
    value: 'b46cc216f6f05e0a',
  },

  {
    key: Course.ELEMENTARY_SCHOOL_MATH,
    value: '5003dd025d28418a',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_MATH,
    value: '674dffa5592f3d35',
  },
  {
    key: Course.HIGH_SCHOOL_MATH,
    value: 'df0d70148b76dca1',
  },

  {
    key: Course.ELEMENTARY_SCHOOL_SOCIAL_SCIENCE,
    value: 'e56f7b2edc180d81',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_GEOGRAPHY,
    value: '3341abc32747a557',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_HISTORY,
    value: '3341abc32747a557',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_CIVICS,
    value: '3341abc32747a557',
  },
  {
    key: Course.HIGH_SCHOOL_GEOGRAPHY,
    value: 'f5bfec77a0cdfa2a',
  },
  {
    key: Course.HIGH_SCHOOL_HISTORY,
    value: 'f5bfec77a0cdfa2a',
  },
  {
    key: Course.HIGH_SCHOOL_CIVICS,
    value: 'f5bfec77a0cdfa2a',
  },
  {
    key: Course.ELEMENTARY_SCHOOL_SCIENCE,
    value: '9285d8cf89df6296',
  },
  {
    key: Course.ELEMENTARY_SCHOOL_SCIENCE_EXPERIMENT,
    value: '4bd161ed92c3b1b4',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_BIOLOGY,
    value: 'ca0d5af710229dac',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_PHYSICS_AND_CHEMISTRY,
    value: '150f20b597bd1eba',
  },
  {
    key: Course.JUNIOR_HIGH_SCHOOL_EARTH_SCIENCE,
    value: 'f0fec8be437b444d',
  },
  {
    key: Course.HIGH_SCHOOL_EARTH_SCIENCE,
    value: '4be00fda0952abe7',
  },
  {
    key: Course.HIGH_SCHOOL_PHYSICS,
    value: 'd377d240964d577b',
  },
  {
    key: Course.HIGH_SCHOOL_CHEMISTRY,
    value: 'd377d240964d577b',
  },
  {
    key: Course.HIGH_SCHOOL_BIOLOGY,
    value: 'd377d240964d577b',
  },
  {
    key: Course.MANDARIN_AS_A_FOREIGN_LANGUAGE,
    value: '3b0d4f308a1d4909',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_BRONZE,
    value: '62d6cf9b161a5417',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_SILVER,
    value: '5545d5045a57c32e',
  },
  {
    key: AdditionalCourseMultiInterview.ESSAY_WRITING_GOLD,
    value: '7c3ed1fd12d9cd9a',
  },
];
