import { EApiStatus } from 'constants/common';
import { useQuery } from '@tanstack/react-query';
import axios from 'plugins/api/axios';

const { teachers, oneClubGateway } = axios;

export const useGetServerTime = (isLogin: boolean) => {
  return useQuery({
    queryKey: ['serverTime'],
    enabled: isLogin,
    queryFn: async () => {
      const { data, status } = await teachers.getServerTime();
      if (status !== EApiStatus.SUCCESS) {
        return '';
      }
      return data;
    },
  });
};

export const useGetEarlyLeaveTime = (isLogin: boolean, isEnabled: boolean) => {
  return useQuery({
    queryKey: ['earlyLeaveTime'],
    enabled: isLogin && isEnabled,
    queryFn: async () => {
      const { data, status } = await oneClubGateway.earlyLeaveTime();
      if (status !== EApiStatus.SUCCESS) {
        return 0;
      }
      return data.earlyLeaveTime;
    },
  });
};
