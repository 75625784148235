import { styled } from '@mui/material/styles';
import { Mui } from '@onedesign/ui';

const { Button, Box, Typography } = Mui;

export const WrapperHeader = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
export const BannerContainerStyles = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '13px 12px 13px 16px',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  border: '1px solid #FFE16A',
  background: '#FFF7CD',
  justifyContent: 'space-between',
}));

export const WrapperLeftWarningStyles = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
}));

export const ImgWarningStyles = styled('img')(() => ({
  width: '24px',
  height: '24px',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',

  '&:hover': {
    transform: 'scale(1.1)',
  },

  '&:active': {
    transform: 'scale(0.9)',
  },
}));

export const TextBannerStyles = styled(Typography)(() => ({
  fontFamily: 'Noto Sans TC',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '22px',
  fontStyle: 'normal',
  color: '#7A400B',
  flex: '1 0 0',
  marginLeft: '5px',
}));

export const ContainerBannerReferenceStyles = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  background: '#FEF2F0',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  fontFamily: 'Noto Sans TC',
}));
export const WrapperLeftBannerReferenceStyles = styled('div')(() => ({
  display: 'flex',
  textAlign: 'center',
  color: '#454F5B',
  padding: '10px 28px',
  gap: '12px',
  alignItems: 'center',
  '@media(max-width: 768px)': {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
}));
export const LeftBannerReferenceStyles = styled('div')(() => ({
  display: 'inline-flex',
  textAlign: 'center',
  color: '#454F5B',
  gap: '12px',
  alignItems: 'center',
  '@media(max-width: 768px)': {
    flexDirection: 'row',
    width: '100%',
    float: 'left',
  },
}));

export const TextLeftBannerReferenceStyles = styled(Typography)(() => ({
  fontFamily: 'Noto Sans TC',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '28px',
  fontStyle: 'normal',
  color: '#454F5B',
  textAlign: 'center',
  '@media(max-width: 768px)': {
    // whiteSpace: 'nowrap',
    // textOverflow: 'hidden',
    // overflow: 'hidden',
    fontSize: '14px',
    fontWeight: '500',
    color: '#454F5B',
    marginTop: '4px',
  },
}));

export const ButtonLeftBannerReferenceStyles = styled(Button)(() => ({
  display: 'flex',
  height: '36px',
  padding: '4px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '6px',
  border: '1px solid rgba(145, 158, 171, 0.32)',
  background: '#FFF',
  '@media(max-width: 768px)': {
    height: '30px',
    marginTop: '-15px',
  },
}));
export const TextButtonBannerReferenceStyles = styled(Typography)(() => ({
  display: 'flex',
  height: '36px',
  fontSize: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  color: '#212B36',
  fontWeight: '700',
  '@media(max-width: 768px)': {
    fontSize: '13px',
    color: '#212B36',
  },
}));
export const ImgLogoStyles = styled('img')(() => ({
  width: '139.333px',
  height: '44px',
}));

export const ImgCloseBannerStyles = styled('img')(() => ({
  width: '40px',
  height: '40px',
  cursor: 'pointer',
  padding: '8.4px',
  gap: '14px',
  display: 'flex',
  marginRight: '1%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  transition: 'transform 0.2s ease-in-out',

  '&:hover': {
    transform: 'scale(1.1)',
  },

  '&:active': {
    transform: 'scale(0.9)',
  },
  '@media(max-width: 768px)': {
    marginRight: '30px',
  },
}));
