import { weekMap } from 'constants/course/batchScheduledCourse.constant';
import {
  READ_STATUS,
  MESSAGE_TYPE_NAME,
  MESSAGE_TYPE_AVATAR,
  MESSAGE_TYPE,
} from 'constants/message';
import { dateFormate } from 'constants/teacherRecruitment';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'routes/routeConfig';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Divider,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// components
import {
  IconButtonAnimate,
  Iconify,
  MenuPopover,
  Scrollbar,
} from '@onedesign/ui';
import { useNotificationService } from 'services/NotificationService';
import { INotification } from 'types/notification';
import { fToNow, fToTime } from 'utils/formatTime';
import { format } from 'date-fns';
import { ICourse, TCourseFrequency } from 'types/course';
import { t } from 'utils/i18n';

// ----------------------------NotificationItem---------------------------

const getCourseTime = (dayOfWeek: number, startTime: string) => {
  // 週一 18:30 - 18:55 (25分)
  return `週${weekMap[dayOfWeek as keyof typeof weekMap]} ${startTime}`;
};

interface NotificationItemProps {
  notification: INotification;
}

const NotificationItem: FC<NotificationItemProps> = ({ notification }) => {
  const courseInfo: ICourse = JSON.parse(notification.contentObject);
  const courseTags = courseInfo.tags.map(item => item.name).join('/');

  const { avatar, title } = renderContent(notification, courseInfo, courseTags);
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.readStatus === READ_STATUS.READ && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            {notification.messageType !== MESSAGE_TYPE.courseReminder &&
              notification.createdAt && (
                <>
                  <Iconify
                    icon="eva:clock-outline"
                    sx={{ mr: 0.5, width: 16, height: 16 }}
                  />
                  {fToNow(notification.createdAt)}
                </>
              )}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

// ----------------------------------------------------------------------

function renderContent(
  notification: INotification,
  courseInfo: ICourse,
  courseTags: string,
) {
  const formatDate = (date: Date | string | number, formatString: string) => {
    return format(new Date(date), formatString);
  };

  const renderMessageContent = () => {
    const normalCourseDateAndTime = `${formatDate(
      courseInfo.startAt,
      dateFormate,
    )} ${fToTime(courseInfo.startAt)}~${fToTime(courseInfo.endAt)}`;

    const batchScheduledCourseDate = `${formatDate(
      courseInfo.startAt,
      dateFormate,
    )}-${formatDate(courseInfo.endAt, dateFormate)}`;

    const batchScheduledCourseTime =
      notification.batchCourseScheduleRequestId !== null
        ? ((courseInfo as any)?.courseFrequency ?? [])
            .map((item: TCourseFrequency, index: number) => {
              return `${getCourseTime(item.dayOfWeek, item.startTime)}${
                index ===
                ((courseInfo as any)?.courseFrequency ?? []).length - 1
                  ? ''
                  : ' | '
              }`;
            })
            .join('')
        : '';

    const TypographyStyle = {
      color: 'text.secondary',
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '2',
    };
    switch (notification.messageType) {
      case MESSAGE_TYPE.courseCancel:
        if (notification.batchCourseScheduleRequestId !== null) {
          return (
            <Typography component="span" variant="body2" sx={TypographyStyle}>
              {batchScheduledCourseDate} {batchScheduledCourseTime} {courseTags}
              {t('notification.classCancelled', '課程已取消')}
            </Typography>
          );
        } else {
          return (
            <Typography component="span" variant="body2" sx={TypographyStyle}>
              {normalCourseDateAndTime} {courseTags}
              {t('notification.classCancelled', '課程已取消')}
            </Typography>
          );
        }
      case MESSAGE_TYPE.batchCourseScheduleNotice:
        return (
          <Typography component="span" variant="body2" sx={TypographyStyle}>
            {`${batchScheduledCourseDate} ${batchScheduledCourseTime} ${courseTags} 已排課`}
          </Typography>
        );
      case MESSAGE_TYPE.courseScheduleNotice:
        return (
          <Typography component="span" variant="body2" sx={TypographyStyle}>
            {`${normalCourseDateAndTime} ${courseTags} 已排課`}
          </Typography>
        );
      default:
        return (
          <Typography
            component="span"
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >
            {notification.content}
          </Typography>
        );
    }
  };

  const title = (
    <>
      <Typography variant="subtitle2">
        {MESSAGE_TYPE_NAME[notification.messageType]()}
      </Typography>
      {renderMessageContent()}
    </>
  );
  return {
    avatar: (
      <img
        alt={notification.messageType}
        src={MESSAGE_TYPE_AVATAR[notification.messageType]}
      />
    ),
    title,
  };
}
export default function NotificationsPopover() {
  const navigate = useNavigate();
  const { notifications } = useNotificationService();

  const totalUnRead = notifications?.filter(
    item => item.readStatus === READ_STATUS.UNREAD,
  ).length;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  // const handleMarkAllAsRead = () => {
  //   setNotifications(
  //     notifications.map(notification => ({
  //       ...notification,
  //       isRead: false,
  //     })),
  //   );
  // };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
        css={{}}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {t('layouts.dashboard.header.notificationPopover.notify', '通知')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t(
                'layouts.dashboard.header.notificationPopover.totalUnread',
                '您有 {{totalUnRead}} 則未讀訊息',
                { totalUnRead },
              )}
            </Typography>
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate
                color="primary"
                onClick={handleMarkAllAsRead}
                css={{}}
              >
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'solid' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding>
            {notifications.slice(0, 5).map(notification => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'solid' }} />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => navigate(PATH.NOTIFICATION, { replace: true })}
          >
            {t(
              'layouts.dashboard.header.notificationPopover.viewAll',
              '查看全部',
            )}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
