import axiosOrigin, { AxiosInstance } from 'axios';
import {
  DeleteTeacherRecruitmentRelationsDto,
  ICheckExistsAccountBody,
  IIsExist,
  InitTeacherBody,
  InitTeacherResponse,
  TRCApiResponse,
  TeacherRecruitmentDto,
  UploadAvatarBody,
  UploadAvatarResponse,
  UploadFileBody,
  UploadFileResponse,
  UpdateTeacherBody,
  MigrateTeacherBody,
  MigrateTeacherResponse,
  TeacherRecruitmentContract,
  TRContractVariable,
  ContractType,
} from 'types/teacherRecruitment';

export const teacherRecruitApi = (axios: AxiosInstance, teacher: string) => ({
  deleteTeacher(id: string): TRCApiResponse<void> {
    return axios.delete(`${teacher}/${id}`);
  },
  updateWhenRegister(
    email: string,
    data: UpdateTeacherBody,
  ): TRCApiResponse<TeacherRecruitmentDto> {
    return axios.patch(`${teacher}/email/${email}`, data);
  },

  initTeacher(data: InitTeacherBody): TRCApiResponse<InitTeacherResponse> {
    return axios.post(`${teacher}`, data);
  },

  migrateTeacher(
    data: MigrateTeacherBody,
  ): TRCApiResponse<MigrateTeacherResponse> {
    return axios.post(`${teacher}`, data);
  },

  checkExistsAccount(data: ICheckExistsAccountBody): TRCApiResponse<IIsExist> {
    return axios.post(`${teacher}/exist-check`, data);
  },
  uploadAvatar(
    username: string,
    data: UploadAvatarBody,
  ): TRCApiResponse<UploadAvatarResponse> {
    const bodyFormData = new FormData();
    bodyFormData.append('file', data.file);
    return axios.post(`${teacher}/storage/avatar/${username}`, bodyFormData);
  },

  removeAvatar(avatarImage: string): TRCApiResponse<TRCApiResponse<void>> {
    return axios.delete(`${teacher}/storage/avatar/${avatarImage}`);
  },

  uploadFile(
    data: UploadFileBody,
    username: string,
  ): TRCApiResponse<UploadFileResponse> {
    const bodyFormData = new FormData();
    bodyFormData.append('file', data.file);
    return axios.post(`${teacher}/storage/file/${username}`, bodyFormData);
  },

  get(oneClubId: string): TRCApiResponse<TeacherRecruitmentDto> {
    return axios.get(`${teacher}/oneClubId/${oneClubId}`);
  },
  updateByOneClubId(
    oneClubId: string,
    payload: TeacherRecruitmentDto,
  ): TRCApiResponse<TeacherRecruitmentDto> {
    return axios.patch(`${teacher}/oneClubId/${oneClubId}`, payload);
  },
  update(
    id: string,
    payload: TeacherRecruitmentDto,
  ): TRCApiResponse<TeacherRecruitmentDto> {
    return axios.patch(`${teacher}/${id}`, payload);
  },

  getAvatarURL(url: string) {
    return `${teacher}/storage/avatar/${url}`;
  },

  getFileURL(url: string) {
    return `${teacher}/storage/file/${url}`;
  },

  getFileWithHeaders(url: string) {
    return axiosOrigin.get(`${teacher}/storage/file/${url}`);
  },

  deleteModel(
    username: string,
    payload: DeleteTeacherRecruitmentRelationsDto,
  ): TRCApiResponse<void> {
    return axios.delete(`${teacher}/oneClubId/${username}`, { data: payload });
  },

  getLatestContractTemplate(
    contractType: ContractType = ContractType.EMPLOYMENT_CONTRACT,
  ): TRCApiResponse<TeacherRecruitmentContract> {
    return axios.get(`${teacher}/contract/latest`, {
      params: { contractType },
    });
  },

  getContractVariables(
    contractType: ContractType = ContractType.EMPLOYMENT_CONTRACT,
  ): TRCApiResponse<TRContractVariable[]> {
    return axios.get(`${teacher}/contract/variables`, {
      params: { contractType },
    });
  },

  sendContract(
    oneClubId: string,
    contractContent: string,
  ): TRCApiResponse<any> {
    return axios.post(`${teacher}/send-contract/${oneClubId}`, {
      contractContent,
    });
  },
  sendTerminatedContract(
    oneClubId: string,
    contractContent: string,
  ): TRCApiResponse<any> {
    return axios.post(`${teacher}/send-terminated-contract/${oneClubId}`, {
      contractContent,
    });
  },
});
