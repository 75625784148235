import {
  InterviewStatus,
  ReviewStatus,
  TRCApiErrorCode,
} from 'constants/teacherRecruitment';
import {
  CHECK_POPUP_REQUEST_SIGN_EMPLOYMENT_CONTRACT,
  TeacherStatus,
} from 'constants/common';
import { PATH } from 'routes/routeConfig';
import { Icon, SvgName } from 'components/Icon';
import { useFeatureToggle, useSnackbar } from 'hooks/index';
import { ERemoteConfigParameter } from 'services/FeatureToggleService/remoteConfigService.constants';
import { useEffect, useState } from 'react';
import axios from 'plugins/api/axios';
import { useProfileService } from 'services/ProfileService';
import { useLocation } from 'react-router-dom';
import { t } from 'utils/i18n';
import { validateStatusOfOldTeacherToRequestSignContract } from 'utils/teacherRecruitment';
const { teacherRecruit } = axios;

// ----------------------------------------------------------------------

const getIcon = (name: SvgName) => <Icon name={name} />;

const ICONS = {
  suitcase: getIcon(SvgName.Suitcase),
};

export interface NavItem {
  title: string;
  path: string;
  icon?: JSX.Element;
  children?: NavItem[];
  disabled?: boolean;
}

export enum ApplicationStage {
  CREATE = 1,
  SUBMIT = 2,
  CHECK = 3,
  SIGN_CONTRACT = 4,
}
export default () => {
  const [enabledFeatures] = useFeatureToggle();

  const { pathname } = useLocation();

  const isTeacherRecruitmentEnabled = enabledFeatures(
    ERemoteConfigParameter.TEACHER_APPLICATION,
  );

  const isTeacherRecruitmentSigningEnabled = enabledFeatures(
    ERemoteConfigParameter.TEACHER_SIGNING,
  );

  const { enqueueSnackbar } = useSnackbar();

  const { profile, infoTeacherRecruitment } = useProfileService();
  const username = profile.oneClubId;

  const statusOfOldTeacherFromProfile = profile?.status as string;

  const [currentApplicationState, setCurrentApplicationStage] =
    useState<ApplicationStage>(ApplicationStage.CREATE);
  const [curNavs, setCurNavs] = useState<
    {
      subheader: string;
      items: NavItem[];
    }[]
  >([]);

  useEffect(() => {
    if (!username) {
      return;
    }
    teacherRecruit.get(username).then(res => {
      if (res.err === TRCApiErrorCode.SUCCESS && res.data) {
        const data = res.data;

        const applicationReviews = data.applicationReview?.filter(
          e => e.interviewStatus !== InterviewStatus.EDITING,
        );

        if (applicationReviews && applicationReviews.length > 0) {
          const lastApplicationReview =
            applicationReviews[applicationReviews.length - 1];
          if (
            lastApplicationReview.secondaryReviewStatus === ReviewStatus.PASSED
          ) {
            setCurrentApplicationStage(ApplicationStage.SIGN_CONTRACT);
            return;
          }
        }
        if (applicationReviews && applicationReviews.length > 0) {
          setCurrentApplicationStage(ApplicationStage.CHECK);
          return;
        }
        if (data.jobExperience?.employmentStatus) {
          setCurrentApplicationStage(ApplicationStage.SUBMIT);
        }
        enqueueSnackbar(
          t(
            'layouts.dashboard.navbar.unverifiedNavConfig.getUserSuccess',
            'Get user successfully',
          ),
        );
      } else {
        if (profile.status === TeacherStatus.UNVERIFIED) {
          enqueueSnackbar(
            t(
              'layouts.dashboard.navbar.unverifiedNavConfig.getUserFailed',
              'Get user failed',
            ),
            { variant: 'error' },
          );
        }
      }
    });
  }, [username, pathname]);

  useEffect(() => {
    const navs: NavItem[] = [];
    if (isTeacherRecruitmentEnabled) {
      const applicationNav = {
        title: t('header.teacherApplication', '職缺申請'),
        path: PATH.TEACHER_APPLICATION,
        icon: ICONS.suitcase,
        children: [
          {
            title: t('header.trialTeaching', '試教申請'),
            path: PATH.TEACHER_APPLICATION_CREATE,
          },
          {
            title: t('header.teacherExperience', '個人經歷'),
            path: PATH.TEACHER_EXPERIENCE,
          },
        ],
      };

      const statusOfOldTeacher =
        validateStatusOfOldTeacherToRequestSignContract(
          statusOfOldTeacherFromProfile as TeacherStatus,
        );

      if (
        (statusOfOldTeacher &&
          infoTeacherRecruitment?.requestSignContractFromMMSTime &&
          !infoTeacherRecruitment?.contractSignedTime) ||
        (isTeacherRecruitmentSigningEnabled &&
          currentApplicationState >= ApplicationStage.SIGN_CONTRACT)
      ) {
        applicationNav.children.push({
          title: t('header.teacherEmploymentContract', '教師聘用合約'),
          path: PATH.TEACHER_EMPLOYMENT_CONTRACT,
        });
        if (
          statusOfOldTeacher &&
          infoTeacherRecruitment?.requestSignContractFromMMSTime &&
          !infoTeacherRecruitment?.contractSignedTime
        ) {
          applicationNav.children.push({
            title: t('header.requestSignEmploymentContract', '教師聘用合約'),
            path: PATH.REQUEST_SIGN_EMPLOYMENT_CONTRACT,
          });
          localStorage.setItem(
            CHECK_POPUP_REQUEST_SIGN_EMPLOYMENT_CONTRACT.IS_DISPLAYED,
            CHECK_POPUP_REQUEST_SIGN_EMPLOYMENT_CONTRACT.YES,
          );
        }
      }

      navs.push(applicationNav);
    }

    setCurNavs([
      {
        subheader: `general v${import.meta.env.VITE_APP_VERSION || '1.0.0'}`, //  general v3.3.0
        items: navs,
      },
    ]);
  }, [currentApplicationState, isTeacherRecruitmentEnabled]);

  return {
    configs: curNavs,
  };
};
