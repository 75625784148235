import {
  CHECK_POPUP_REQUEST_SIGN_EMPLOYMENT_CONTRACT,
  EApiStatus,
  ERROR_TEACHER_BACKEND,
  TeacherStatus,
} from 'constants/common';
import { TRCApiErrorCode } from 'constants/teacherRecruitment';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'routes/routeConfig';
import { useSnackbar } from 'hooks/index';
import axios from 'plugins/api/axios';
import cookie, { KeyCookie } from 'plugins/cookie/cookie';
import { ILoginBody, TLoginContext } from 'types/login';
import { t as trans } from 'utils/i18n';
import {
  InitTeacherBody,
  TeacherRecruitmentDto,
} from 'src/types/teacherRecruitment';
import { IProfile } from 'src/types/profile';
import {
  IDataExistReferenceSystem,
  IPayload,
  IPayloadCreateReferenceSystem,
  IPayloadUpdate,
  UserType,
} from 'types/referenceSystem';
import { ICreateRecommendationCode } from 'types/recommendationCode';

enum ErrorCode {
  Forbidden = 403,
  NotFound = 404,
}
const {
  oneClubGatewayStaffMember,
  oneClubGateway,
  oneClub,
  teacherRecruit,
  teachers,
  referenceSystem,
  recommendationCode,
} = axios;
const UnVerified = '信箱不存在';
const Verified = '信箱已存在';
const TeacherRecruitmentApiReturnStatus = {
  SUCCESS: 'SUCCESS',
};
const AuthStatus = {
  SUCCESS: 'SUCCESS',
};
export const CommonStatus = {
  SUCCESS: 'SUCCESS',
};
export const initAccountDataByOneClubSystem = {
  username: '',
  password: '',
  from: 'Nani',
} as {
  username: string;
  password: string;
  from: 'Nani';
};
export const LoginContext = createContext<TLoginContext>({
  isLogin: false,
  setIsLogin: () => false,
  login: async () => {},
  logout: async () => {},
  username: '',
  dataCheckEmailExist: '',
  isShowMemberConfirm: false,
  valueAccountByOneClubSystem: initAccountDataByOneClubSystem,
});

export enum AccountOrigin {
  TEACHER_RECRUITMENT = 'TEACHER_RECRUITMENT',
  MMS_TEACHER = 'MMS_TEACHER',
}

export enum ThrowCode {
  Failed_GenCode = 'Failed_GenCode',
  Failed_Create_TeacherRecruitment = 'Failed_Create_TeacherRecruitment',
  Failed_Create_ReferenceSystem = 'Failed_Create_ReferenceSystem',
  Failed_Update_ReferralCode_TeacherRecruitment = 'Failed_Update_ReferralCode_TeacherRecruitment',
  Failed_Update_ReferralCode_ReferenceSystem = 'Failed_Update_ReferralCode_ReferenceSystem',
}

const getAccountOrigin = (
  oldTeacherData: IProfile,
  recruitmentTeacherData: TeacherRecruitmentDto,
) => {
  const oldTeacherCreateDate = oldTeacherData.createdAt;
  const recruitmentTeacherCreateDate = recruitmentTeacherData.createdAt;
  if (!oldTeacherCreateDate) {
    throw new Error('Old teacher create date is null');
  }
  if (!recruitmentTeacherCreateDate) {
    throw new Error('recruitment teacher create date is null');
  }

  if (new Date(oldTeacherCreateDate) < new Date(recruitmentTeacherCreateDate)) {
    return AccountOrigin.MMS_TEACHER;
  }
  return AccountOrigin.TEACHER_RECRUITMENT;
};

interface ICreateAccountReferenceSystemWithTypeOneClubID {
  jwt: string;
  dataLogin: ILoginBody;
  dataTeacherRecruit: TeacherRecruitmentDto;
}
interface IHandleAccountReferenceSystemTypeOneClubID {
  jwt: string;
  dataReferenceSystem: IDataExistReferenceSystem;
  dataTeacherRecruit: TeacherRecruitmentDto;
}
interface IHandleAccountReferenceSystemTypeReferenceSystem {
  jwt: string;
  dataReferenceSystem: IDataExistReferenceSystem;
}

export const LoginProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [dataCheckEmailExist, setDataCheckEmailExist] = useState<string>('');
  const [valueAccountByOneClubSystem, setValueAccountByOneClubSystem] =
    useState<ILoginBody>(initAccountDataByOneClubSystem);
  const [isShowMemberConfirm, setIsShowingMemberConfirm] = useState(false);
  const token = cookie.get(KeyCookie.newTeacherClient);
  const oneClassToken = cookie.get(KeyCookie.oneClassClient) as {
    code: string;
    jwt: string;
  };
  /* Sign out */
  const logout = async () => {
    if (oneClassToken?.jwt?.includes(token)) {
      cookie.remove(KeyCookie.oneClassClient);
    }
    cookie.remove(KeyCookie.newTeacherClient);
    if (
      localStorage.getItem(
        CHECK_POPUP_REQUEST_SIGN_EMPLOYMENT_CONTRACT.IS_DISPLAYED,
      )
    ) {
      localStorage.removeItem(
        CHECK_POPUP_REQUEST_SIGN_EMPLOYMENT_CONTRACT.IS_DISPLAYED,
      );
    }
    setIsLogin(false);
    navigate(PATH.LOGIN, { replace: true });
  };

  const removeToken = () => {
    const token = cookie.get(KeyCookie.newTeacherClient);
    const oneClassToken = cookie.get(KeyCookie.oneClassClient) as {
      code: string;
      jwt: string;
    };
    if (oneClassToken?.jwt?.includes(token)) {
      cookie.remove(KeyCookie.oneClassClient);
    }
    cookie.remove(KeyCookie.newTeacherClient);
    setIsLogin(false);
  };

  const getReferralCodeAfterGen = async (
    payloadCreateCode: ICreateRecommendationCode,
  ) => {
    const {
      data: dataRecommendationCode,
      err: errRecommendationCode,
      msg: msgRecommendationCode,
    } = await recommendationCode.createCode(payloadCreateCode);

    if (!dataRecommendationCode && errRecommendationCode) {
      enqueueSnackbar(msgRecommendationCode, { variant: 'error' });
      throw new Error(ThrowCode.Failed_GenCode);
    }

    return dataRecommendationCode;
  };
  const createRecordInTeacherRecruitment = async (
    dataReferenceSystem: IDataExistReferenceSystem,
    invitationCode: string,
  ) => {
    const payloadCreateTeacherRecruitment = {
      oneClubId: dataReferenceSystem.userId,
      firstName: dataReferenceSystem.userId,
      fullName: dataReferenceSystem.userId,
      lastName: '',
      telephoneCountryCode: '',
      telephone: '',
      gender: 'male',
      staffType: 'inside',
      status: TeacherStatus.UNVERIFIED,
      email: dataReferenceSystem.email,
      referralCode: invitationCode,
    } as InitTeacherBody;

    const {
      data: dataCreateTeacherRecruitment,
      err: errCreateTeacherRecruitment,
      msg: msgCreateTeacherRecruitment,
    } = await teacherRecruit.initTeacher(payloadCreateTeacherRecruitment);

    if (
      errCreateTeacherRecruitment !== TRCApiErrorCode.SUCCESS &&
      !dataCreateTeacherRecruitment
    ) {
      enqueueSnackbar(msgCreateTeacherRecruitment, {
        variant: 'error',
      });
      throw new Error(ThrowCode.Failed_Create_TeacherRecruitment);
    }
  };

  const createAccountReferenceSystemWithReferralCodeAndOneClubIdType = async (
    payloadCreateReferenceSystem: IPayloadCreateReferenceSystem,
  ) => {
    const {
      data: dataCreateReferenceSystem,
      err: errCreateReferenceSystem,
      msg: msgCreateReferenceSystem,
    } = await referenceSystem.create(payloadCreateReferenceSystem);

    if (!dataCreateReferenceSystem && errCreateReferenceSystem) {
      enqueueSnackbar(msgCreateReferenceSystem, { variant: 'error' });
      throw new Error(ThrowCode.Failed_Create_ReferenceSystem);
    }
  };
  const updateReferralCodeInTeacherRecruitment = async (
    invitationCode: string,
    IDRecord: string,
  ) => {
    const payloadUpdateTeacherRecruitment = {
      referralCode: invitationCode,
    } as TeacherRecruitmentDto;

    const {
      data: dataUpdateTeacherRecruitment,
      err: errUpdateTeacherRecruitment,
      msg: msgUpdateTeacherRecruitment,
    } = await teacherRecruit.update(IDRecord, payloadUpdateTeacherRecruitment);

    if (
      errUpdateTeacherRecruitment !== TRCApiErrorCode.SUCCESS &&
      !dataUpdateTeacherRecruitment
    ) {
      enqueueSnackbar(msgUpdateTeacherRecruitment, {
        variant: 'error',
      });
      throw new Error(ThrowCode.Failed_Update_ReferralCode_TeacherRecruitment);
    }
  };
  const updateReferralCodeInReferenceSystem = async (
    invitationCode: string,
    IDRecord: string,
  ) => {
    const payloadUpdateReferenceSystem = {
      referralCode: invitationCode,
    } as IPayloadUpdate;

    const { data: dataUpdateReferenceSystem, msg: msgUpdateReferenceSystem } =
      await referenceSystem.update(IDRecord, payloadUpdateReferenceSystem);

    if (!dataUpdateReferenceSystem) {
      enqueueSnackbar(msgUpdateReferenceSystem, {
        variant: 'error',
      });
      throw new Error(ThrowCode.Failed_Update_ReferralCode_ReferenceSystem);
    }
  };

  const handleCreateAccountReferenceSystemWithTypeOneClubID = async (
    dataToCreateAccount: ICreateAccountReferenceSystemWithTypeOneClubID,
  ) => {
    const payloadCreateCode = {
      jwt: dataToCreateAccount.jwt,
    } as ICreateRecommendationCode;

    const dataRecommendationCode = await getReferralCodeAfterGen(
      payloadCreateCode,
    );

    const payloadCreateReferenceSystem = {
      userType: UserType.ONECLUB_ID,
      userId: dataToCreateAccount.dataLogin.username,
      referralCode: dataRecommendationCode?.invitationCode,
      email: dataToCreateAccount.dataTeacherRecruit.email,
    } as IPayloadCreateReferenceSystem;

    await createAccountReferenceSystemWithReferralCodeAndOneClubIdType(
      payloadCreateReferenceSystem,
    );

    await updateReferralCodeInTeacherRecruitment(
      dataRecommendationCode?.invitationCode as string,
      dataToCreateAccount.dataTeacherRecruit.id as string,
    );
  };

  const handleAccountReferenceSystemWithTypeOneClubID = async (
    dataToHandle: IHandleAccountReferenceSystemTypeOneClubID,
  ) => {
    const payloadCreateCode = {
      jwt: dataToHandle.jwt,
    } as ICreateRecommendationCode;

    const dataReferenceSystem = dataToHandle.dataReferenceSystem;
    const dataTeacherRecruitment = dataToHandle.dataTeacherRecruit;

    if (
      dataReferenceSystem?.referralCode &&
      !dataTeacherRecruitment?.referralCode
    ) {
      await updateReferralCodeInTeacherRecruitment(
        dataReferenceSystem.referralCode,
        dataTeacherRecruitment?.id as string,
      );
    } else {
      const dataRecommendationCode = await getReferralCodeAfterGen(
        payloadCreateCode,
      );

      await updateReferralCodeInTeacherRecruitment(
        dataRecommendationCode?.invitationCode as string,
        dataTeacherRecruitment.id as string,
      );

      await updateReferralCodeInReferenceSystem(
        dataRecommendationCode?.invitationCode as string,
        dataReferenceSystem.id,
      );
    }
  };

  const handleAccountReferenceSystemWithTypeReferenceSystem = async (
    dataToHandle: IHandleAccountReferenceSystemTypeReferenceSystem,
  ) => {
    const dataReferenceSystem = dataToHandle.dataReferenceSystem;

    if (dataReferenceSystem.referralCode) {
      await createRecordInTeacherRecruitment(
        dataReferenceSystem,
        dataReferenceSystem.referralCode as string,
      );
    } else {
      const payloadCreateCode = {
        jwt: dataToHandle.jwt,
      } as ICreateRecommendationCode;

      const dataRecommendationCode = await getReferralCodeAfterGen(
        payloadCreateCode,
      );

      await createRecordInTeacherRecruitment(
        dataReferenceSystem,
        dataRecommendationCode?.invitationCode as string,
      );

      await updateReferralCodeInReferenceSystem(
        dataRecommendationCode?.invitationCode as string,
        dataReferenceSystem.id,
      );
    }
  };
  /* login */
  const login = async (data: ILoginBody) => {
    let typeAccountFromReferenceSystem = '';
    // Obtain OneClub token
    const { code, data: errorMsg, jwt } = await oneClub.getAuthToken(data);
    let userName = null;
    if (code === 'Failure') {
      enqueueSnackbar(errorMsg, { variant: 'error' });
      return;
    }

    if (data && code === AuthStatus.SUCCESS) {
      userName = data.username;
      setValueAccountByOneClubSystem({
        username: userName,
        password: data.password,
        from: 'Nani',
      });
      setUsername(userName);
    }

    if (!userName) {
      setIsLogin(false);
      return;
    }

    if (code !== AuthStatus.SUCCESS) {
      return;
    }

    // check type account is oneClubId or referenceSystem
    let globalDataReferenceSystem: IDataExistReferenceSystem = {
      id: '',
      userType: UserType.ONECLUB_ID,
      userId: '',
      referralCode: '',
      referredBy: '',
      createdAt: '',
      updatedAt: '',
      appliedReferralCodeTime: '',
      email: '',
      emailVerified: false,
    };

    const paramsCheckRecordInReferenceSystemExistTypeReferenceSystem = {
      userType: UserType.REFERENCE_SYSTEM,
      userId: data.username,
    } as IPayload;

    const {
      data: dataReferenceSystemTypeReferenceSystem,
      msg: msgReferenceSystemTypeReferenceSystem,
      err: errReferenceSystemTypeReferenceSystem,
    } = await referenceSystem.getByUserIdAndType(
      paramsCheckRecordInReferenceSystemExistTypeReferenceSystem,
    );

    if (
      dataReferenceSystemTypeReferenceSystem &&
      errReferenceSystemTypeReferenceSystem !== ErrorCode.NotFound &&
      msgReferenceSystemTypeReferenceSystem === CommonStatus.SUCCESS
    ) {
      setIsLogin(false);
      enqueueSnackbar(trans('login.invalidAccount', 'Invalid account'), {
        variant: 'error',
      });
      return;
    }

    const paramsCheckRecordInReferenceSystemExistTypeOneClubID = {
      userType: UserType.ONECLUB_ID,
      userId: data.username,
    } as IPayload;

    const {
      data: dataReferenceSystemTypeOneClubID,
      msg: msgReferenceSystemTypeOneClubID,
      err: errReferenceSystemTypeOneClubID,
    } = await referenceSystem.getByUserIdAndType(
      paramsCheckRecordInReferenceSystemExistTypeOneClubID,
    );

    if (
      dataReferenceSystemTypeOneClubID &&
      errReferenceSystemTypeOneClubID !== ErrorCode.NotFound &&
      msgReferenceSystemTypeOneClubID === CommonStatus.SUCCESS
    ) {
      typeAccountFromReferenceSystem = UserType.ONECLUB_ID;
      globalDataReferenceSystem = dataReferenceSystemTypeOneClubID;
    }

    cookie.set(KeyCookie.newTeacherClient, jwt);
    if (!oneClassToken) {
      cookie.setNaniOneClassCookie(
        KeyCookie.oneClassClient,
        JSON.stringify({ code, jwt }),
      );
    }

    // #region check verify email
    let emailVerificationStatus = '';
    const { error: oldTeacherError, data: oldTeacherData } =
      await teachers.getMe();

    const { msg: teacherRecruitResponseMsg, data: teacherRecruitData } =
      await teacherRecruit.get(userName);

    let accountOrigin: AccountOrigin | undefined;
    if (oldTeacherData && teacherRecruitData) {
      try {
        accountOrigin = getAccountOrigin(oldTeacherData, teacherRecruitData);
      } catch (err) {
        window.console.error(err);
      }
    }

    const isSkipCheckingVerifiedEmailOfMMSAccount =
      accountOrigin === AccountOrigin.MMS_TEACHER;

    if (
      teacherRecruitResponseMsg === TeacherRecruitmentApiReturnStatus.SUCCESS &&
      teacherRecruitData &&
      !isSkipCheckingVerifiedEmailOfMMSAccount
    ) {
      const teacherRecruitEmail = teacherRecruitData.email;

      if (teacherRecruitEmail && teacherRecruitEmail.length) {
        emailVerificationStatus = await oneClub.emailExist({
          email: teacherRecruitEmail,
        });

        if (!emailVerificationStatus) return;

        if (
          emailVerificationStatus !== UnVerified &&
          emailVerificationStatus !== Verified
        ) {
          return;
        }

        if (emailVerificationStatus === UnVerified) {
          setDataCheckEmailExist(emailVerificationStatus);
          removeToken();
          return;
        }

        if (emailVerificationStatus === Verified) {
          setDataCheckEmailExist(emailVerificationStatus);
        }
      }
    }

    // #endregion check verify email

    // check is a customer
    const { data: dataCustomer, status: statusCustomer } =
      await oneClubGateway.checkIsACustomer();

    if (dataCustomer !== undefined || statusCustomer === EApiStatus.SUCCESS) {
      removeToken();
      enqueueSnackbar(
        trans(
          'login.isStaffMemberOrCustomer',
          'This account already has another identity. Please use a different account to log in or register a new account',
        ),
        { variant: 'error' },
      );

      return;
    }

    //  check is a staff member
    const { error: errorStaffMember, status: statusStaffMember } =
      await oneClubGatewayStaffMember.checkIsAStaffMember();
    if (
      errorStaffMember?.errorCode !== undefined &&
      errorStaffMember?.errorCode !== ErrorCode.Forbidden &&
      statusStaffMember !== EApiStatus.SUCCESS
    ) {
      removeToken();
      enqueueSnackbar(
        trans(
          'login.isStaffMemberOrCustomer',
          'This account already has another identity. Please use a different account to log in or register a new account',
        ),
        { variant: 'error' },
      );

      return;
    }

    const existUsernameInTeacherRecruitment = !!teacherRecruitData;

    // additional check typeAccount to avoid MemberInfor case
    if (
      typeAccountFromReferenceSystem !== UserType.REFERENCE_SYSTEM &&
      typeAccountFromReferenceSystem !== UserType.ONECLUB_ID &&
      oldTeacherError?.exceptionMessage ===
        ERROR_TEACHER_BACKEND.TEACHER_IS_NOT_EXIST &&
      !existUsernameInTeacherRecruitment
    ) {
      setIsShowingMemberConfirm(true);
      setIsLogin(true);
      return;
    }

    if (
      !existUsernameInTeacherRecruitment &&
      (oldTeacherError?.exceptionMessage ===
        ERROR_TEACHER_BACKEND.INSTANT_MESSAGING_USER_CREATE_ERROR ||
        oldTeacherError?.exceptionMessage ===
          ERROR_TEACHER_BACKEND.INSTANT_MESSAGING_USER_GET_ERROR ||
        oldTeacherError?.exceptionMessage ===
          ERROR_TEACHER_BACKEND.INSTANT_MESSAGING_USER_UPDATE_ERROR)
    ) {
      setIsShowingMemberConfirm(false);
      enqueueSnackbar(`${oldTeacherError?.message}`, {
        variant: 'error',
      });
      removeToken();
      return;
    }

    // start main handle sync data between Teacher Recruitment and Reference System
    if (
      !globalDataReferenceSystem.referralCode ||
      !teacherRecruitData?.referralCode
    ) {
      try {
        switch (typeAccountFromReferenceSystem) {
          case '': {
            const paramsCreateAccount = {
              jwt,
              dataLogin: data,
              dataTeacherRecruit: teacherRecruitData,
            };
            await handleCreateAccountReferenceSystemWithTypeOneClubID(
              paramsCreateAccount as ICreateAccountReferenceSystemWithTypeOneClubID,
            );
            break;
          }

          case UserType.ONECLUB_ID: {
            if (teacherRecruitData) {
              const paramsHandleAccountTypeOneClubID = {
                jwt,
                dataReferenceSystem: globalDataReferenceSystem,
                dataTeacherRecruit: teacherRecruitData,
              } as IHandleAccountReferenceSystemTypeOneClubID;
              await handleAccountReferenceSystemWithTypeOneClubID(
                paramsHandleAccountTypeOneClubID,
              );
            }
            break;
          }

          case UserType.REFERENCE_SYSTEM: {
            const paramsHandleAccountTypeReferenceSystem = {
              jwt,
              dataReferenceSystem: globalDataReferenceSystem,
            } as IHandleAccountReferenceSystemTypeReferenceSystem;
            await handleAccountReferenceSystemWithTypeReferenceSystem(
              paramsHandleAccountTypeReferenceSystem,
            );
            break;
          }

          default: {
            break;
          }
        }
      } catch (error) {
        window.console.error(error);
      }
    }
    // end main handle sync data between Teacher Recruitment and Reference System

    setIsLogin(true);
    setIsShowingMemberConfirm(false);
  };

  useEffect(() => {
    if (token) {
      setIsLogin(true);
    }
  }, [token]);

  return (
    <LoginContext.Provider
      value={{
        isLogin,
        setIsLogin,
        login,
        logout,
        username,
        dataCheckEmailExist,
        isShowMemberConfirm,
        valueAccountByOneClubSystem,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginService = () => useContext<TLoginContext>(LoginContext);
