import { lazyLoad } from 'routes/LazyLoad';
import { RouteObject } from 'react-router-dom';
import { PATH } from '../routeConfig';

export const requestSignEmploymentContractRoutes: RouteObject[] = [
  {
    path: PATH.REQUEST_SIGN_EMPLOYMENT_CONTRACT,
    element: lazyLoad(() => import('pages/TeacherEmploymentContract')),
    index: true,
  },
];
