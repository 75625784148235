import {
  TeacherOrigin,
  ReviewStatus,
  TRCApiErrorCode,
} from 'constants/teacherRecruitment';
import { EApiStatus, TeacherStatus } from 'constants/common';
import {
  ApplicationReviewDto,
  TeacherRecruitmentDto,
} from 'types/teacherRecruitment';

import axios from 'plugins/api/axios';
const { teacherRecruit, teachers } = axios;

export const getFileFromUrl = async (
  url: string,
  name: string,
  defaultType?: string,
) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export const getLatestApplicationReview = (
  applicationReviews: ApplicationReviewDto[] | undefined,
) => {
  if (applicationReviews && applicationReviews.length > 0) {
    return applicationReviews[applicationReviews.length - 1];
  }
  return undefined;
};

export const getOfficialApplicationReviews = (
  teacherRecruitData: TeacherRecruitmentDto | null,
  teacherOrigin: TeacherOrigin | undefined,
) => {
  if (!teacherOrigin) {
    return [];
  }

  if (teacherOrigin === TeacherOrigin.OFFICIAL_NEW_TEACHER) {
    const turnOfficialTime = teacherRecruitData?.turnOfficialTime;
    if (!turnOfficialTime) {
      return [];
    }
    const turnOfficialDate = Date.parse(turnOfficialTime);
    return teacherRecruitData?.applicationReview?.filter(item => {
      if (!item?.createdAt) {
        return false;
      }
      const appliedDate = Date.parse(item.createdAt);
      return turnOfficialDate <= appliedDate;
    });
  }

  if (teacherOrigin === TeacherOrigin.OFFICIAL_OLD_TEACHER) {
    return [];
  }

  if (teacherOrigin === TeacherOrigin.UNOFFICIAL_NEW_TEACHER) {
    throw new Error('Not a official teacher');
  }
  return undefined;
};

export const getUnofficialApplicationReviews = (
  teacherRecruitData: TeacherRecruitmentDto | null,
  teacherOrigin: TeacherOrigin | undefined,
) => {
  if (teacherOrigin === TeacherOrigin.OFFICIAL_NEW_TEACHER) {
    const turnOfficialTime = teacherRecruitData?.turnOfficialTime;
    if (turnOfficialTime) {
      const turnOfficialDate = Date.parse(turnOfficialTime);
      return teacherRecruitData?.applicationReview?.filter(item => {
        const appliedDate = Date.parse(item.applyTime);
        return appliedDate < turnOfficialDate;
      });
    } else {
      return teacherRecruitData?.applicationReview;
    }
  }

  if (teacherOrigin === TeacherOrigin.OFFICIAL_OLD_TEACHER) {
    return [];
  }
  if (teacherOrigin === TeacherOrigin.UNOFFICIAL_NEW_TEACHER) {
    throw new Error('Not a official teacher');
  }
  return undefined;
};

export const getListOfPassedCourses = (
  applicationReviews: ApplicationReviewDto[] | undefined,
) => {
  return applicationReviews
    ?.filter(item => item.secondaryReviewStatus === ReviewStatus.PASSED)
    .map(application => {
      return application.course;
    });
};

export const getTeacherOrigin = async (
  username: string,
): Promise<TeacherOrigin | undefined> => {
  const { data: teacherData, status: teacherStatus } = await teachers.getMe();
  const { data: teacherRecruitData, err: teacherRecruitError } =
    await teacherRecruit.get(username);

  const getTeacherSuccess = teacherStatus === EApiStatus.SUCCESS && teacherData;
  const getTeacherRecruitSuccess =
    teacherRecruitError === TRCApiErrorCode.SUCCESS && teacherRecruitData;

  if (teacherRecruitError !== TRCApiErrorCode.SUCCESS) {
    throw new Error(`Can't get user recruitment data`);
  }

  if (getTeacherSuccess && !getTeacherRecruitSuccess) {
    return TeacherOrigin.OFFICIAL_OLD_TEACHER;
  }

  if (getTeacherSuccess && getTeacherRecruitSuccess) {
    return TeacherOrigin.OFFICIAL_NEW_TEACHER;
  }

  return undefined;
};

export const sortApplicationReview = (
  applicationReview: ApplicationReviewDto[],
) => {
  return applicationReview?.sort((a, b) => {
    if (a.applyTime && b.applyTime) {
      const dateA = new Date(a.applyTime);
      const dateB = new Date(b.applyTime);
      return dateA.getTime() - dateB.getTime();
    }

    if (!a.applyTime && !b.applyTime) {
      if (!a.createdAt || !b.createdAt) {
        throw new Error('Invalid review created date');
      }
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA.getTime() - dateB.getTime();
    }

    if (!a.applyTime || !b.applyTime) {
      if (!a.applyTime) {
        return 1;
      }
      return -1;
    }
    return 1;
  });
};

export const validateStatusOfOldTeacherToRequestSignContract = (
  statusOfTeacher: TeacherStatus,
): boolean =>
  [
    TeacherStatus.AVAILABLE,
    TeacherStatus.PAUSED,
    TeacherStatus.INTERN,
  ].includes(statusOfTeacher);
