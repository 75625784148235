import { Navigate, RouteObject } from 'react-router-dom';
import { PATH } from '../routeConfig';
import { lazyLoad } from '../LazyLoad';

export const oneAnswerRoutes: RouteObject[] = [
  {
    element: <Navigate to={PATH.ONE_ANSWER} />,
    index: true,
  },
  {
    path: PATH.ONE_ANSWER,
    element: lazyLoad(() => import('pages/OneAnswer')),
  },
  {
    path: `${PATH.ONE_ANSWER}/:channelUrl`,
    element: lazyLoad(() => import('pages/OneAnswer')),
  },
];
