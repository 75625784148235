export enum EDeletionCmd {
  deleteMember = 'deleteMember',
}

export type TDeletionAccount = {
  cmd: EDeletionCmd;
};

export type TDeletionAccountResponse = {
  err: number;
  msg: string | 'OK!';
};
