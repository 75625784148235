import _ from 'lodash';
import { TeacherRecruitmentDto } from 'types/teacherRecruitment';

export const getChange = (
  data: TeacherRecruitmentDto,
  updatedData: TeacherRecruitmentDto,
) => {
  const keys = _.keys(data);
  let changes: TeacherRecruitmentDto = {};
  if (_.isEqual(data, updatedData)) {
    return null;
  }
  keys.forEach(key => {
    const k = key as keyof TeacherRecruitmentDto;
    if (data[k] !== updatedData[k]) {
      changes = { ...changes, [k]: updatedData[k] };
    }
  });
  return changes;
};

export const getFileFromUrl = async (
  url: string,
  name: string,
  defaultType?: string,
) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export const getFileNameFromURL = (url: string) => {
  return url.replace(/^.*[\\\/]/, '');
};
