import {
  EApiStatus,
  ERROR_TEACHER_BACKEND,
  TeacherStatus,
} from 'constants/common';
import { TRCApiErrorCode } from 'constants/teacherRecruitment';
import { useFeatureToggle, useSnackbar } from 'hooks/index';
import cookie, { KeyCookie } from 'plugins/cookie/cookie';
import axios from 'plugins/api/axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FIRST_ROUTE, PATH } from 'routes/routeConfig';
import { useLoginService } from 'services/LoginService';
import { useLoadingService } from 'services/LoadingService';
import { IProfile, TProfileContext } from 'types/profile';
import { TeacherRecruitmentDto } from 'types/teacherRecruitment';
import { t } from 'utils/i18n';
import { InitTeacherBody } from 'types/teacher';
import { ERemoteConfigParameter } from 'services/FeatureToggleService/remoteConfigService.constants';

const { teachers, teacherRecruit } = axios;

export const ProfileContext = createContext<TProfileContext>({
  profile: {},
  infoTeacherRecruitment: {},
});

export const ProfileProvider: FC = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [enabledFeatures] = useFeatureToggle();
  const isTeacherRecruitmentEnabled = enabledFeatures(
    ERemoteConfigParameter.TEACHER_APPLICATION,
  );
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoadingService();
  const { isLogin, setIsLogin, logout, username } = useLoginService();
  const [isMount, setIsMount] = useState<boolean>(false);
  const [profile, setProfile] = useState<IProfile>({
    firstName: '',
    lastName: '',
    gender: '',
    status: '',
    createdAt: '',
    updatedAt: '',
    staffType: '',
  });

  const [infoTeacherRecruitment, setInfoTeacherRecruitment] =
    useState<TeacherRecruitmentDto>({});
  const isUnauthorizedPath = () => {
    if (location.pathname === PATH.REGISTER) {
      return true;
    }
    if (location.pathname === PATH.LOGIN) {
      return true;
    }
    return false;
  };

  const isTeacherRecruitmentPath = () => {
    const pathname = location.pathname.substring(1, location.pathname.length);
    return (
      pathname.length >= FIRST_ROUTE.TEACHER_APPLICATION.length &&
      pathname.substring(0, FIRST_ROUTE.TEACHER_APPLICATION.length) ===
        FIRST_ROUTE.TEACHER_APPLICATION
    );
  };

  const removeToken = () => {
    const token = cookie.get(KeyCookie.newTeacherClient);
    const oneClassToken = cookie.get(KeyCookie.oneClassClient) as {
      code: string;
      jwt: string;
    };
    if (oneClassToken?.jwt?.includes(token)) {
      cookie.remove(KeyCookie.oneClassClient);
    }
    cookie.remove(KeyCookie.newTeacherClient);
  };

  const handleFetchProfileTeacherRecruitment = async () => {
    showLoading();

    const {
      data: teacherData,
      status: teacherStatus,
      error: teacherErrors,
    } = await teachers.getMe();

    const {
      data: teacherRecruitData,
      msg: teacherRecruitMsg,
      err: teacherRecruitError,
    } = await teacherRecruit.get(username);

    const getTeacherFail = teacherStatus === EApiStatus.FAILURE || !teacherData;
    const getTeacherRecruitFail =
      teacherRecruitError !== TRCApiErrorCode.SUCCESS || !teacherRecruitData;

    if (getTeacherFail && getTeacherRecruitFail) {
      logout();
      hideLoading();
      return;
    }

    if (!getTeacherFail) {
      if (!getTeacherRecruitFail) {
        setInfoTeacherRecruitment({
          ...(teacherRecruitData as unknown as TeacherRecruitmentDto),
        });
      }
      hideLoading();
      enqueueSnackbar(t('services.profileService.signInSuccess', '登入成功'));
      setProfile(() => ({ ...(teacherData as unknown as IProfile) }));
      if (teacherData && teacherData.status === TeacherStatus.UNVERIFIED) {
        if (!isTeacherRecruitmentPath()) {
          navigate(PATH.TEACHER_APPLICATION_CREATE, { replace: true });
        }
      } else {
        isUnauthorizedPath()
          ? navigate(PATH.ROOT, { replace: true })
          : navigate(location.pathname, { replace: true });
      }
      return;
    }

    if (getTeacherRecruitFail) {
      enqueueSnackbar(`${teacherRecruitMsg}`, {
        variant: 'error',
      });
      removeToken();
      setIsLogin(false);
      hideLoading();
      return;
    }

    if (
      teacherErrors &&
      teacherErrors?.exceptionMessage !==
        ERROR_TEACHER_BACKEND.TEACHER_IS_NOT_EXIST
    ) {
      removeToken();
      setIsLogin(false);
      hideLoading();
      return;
    }

    // teacherApiFail = true && teacherRecruitApiFail = false
    // add teacherRecruitment data to teacher-backend

    const newAccount = {
      oneClubId: username,
      firstName: teacherRecruitData.firstName,
      lastName: teacherRecruitData.lastName,
      gender: 'male',
      staffType: 'inside',
      status: TeacherStatus.UNVERIFIED,
      email: teacherRecruitData.email,
    };

    const {
      data: initData,
      error: initError,
      status: initStatus,
    } = await teachers.initTeacher(newAccount as InitTeacherBody);

    if (initStatus === EApiStatus.FAILURE) {
      enqueueSnackbar(`${initError?.errorCode}_${initError?.message}`, {
        variant: 'error',
      });
      removeToken();
      setIsLogin(false);
      hideLoading();
      return;
    }

    const { data: latestData } = await teachers.getMe();
    if (latestData && latestData.status === TeacherStatus.UNVERIFIED) {
      hideLoading();
      enqueueSnackbar(t('services.profileService.signInSuccess', '登入成功'));
      setProfile(() => ({ ...(latestData as unknown as IProfile) }));
      if (!isTeacherRecruitmentPath()) {
        navigate(PATH.TEACHER_APPLICATION_CREATE, { replace: true });
      }
      return;
    }

    enqueueSnackbar(t('services.profileService.signInSuccess', '登入成功'));
    setProfile(() => ({ ...(initData as unknown as IProfile) }));
    hideLoading();
    if (initData.status === TeacherStatus.UNVERIFIED) {
      if (!isTeacherRecruitmentPath()) {
        navigate(PATH.TEACHER_APPLICATION_CREATE, { replace: true });
      }
    } else {
      location.pathname === PATH.LOGIN
        ? navigate(PATH.ROOT, { replace: true })
        : navigate(location.pathname, { replace: true });
    }
  };

  const handleFetchProfileOfficialTeacher = async () => {
    showLoading();
    const { data, error, status } = await teachers.getMe();
    if (status !== EApiStatus.SUCCESS) {
      enqueueSnackbar(`${error?.errorCode}_${error?.message}`, {
        variant: 'error',
      });
      await logout();
      hideLoading();
    } else if (status === EApiStatus.SUCCESS && !data) {
      enqueueSnackbar('查無教師資料', { variant: 'error' });
      removeToken();
      setIsLogin(false);
      hideLoading();
    } else if (status === EApiStatus.SUCCESS) {
      if (username) {
        const { data: teacherRecruitData, err: teacherRecruitError } =
          await teacherRecruit.get(username);
        const getTeacherRecruitFail =
          teacherRecruitError !== TRCApiErrorCode.SUCCESS ||
          !teacherRecruitData;

        if (!getTeacherRecruitFail) {
          setInfoTeacherRecruitment({
            ...(teacherRecruitData as unknown as TeacherRecruitmentDto),
          });
        }
      }

      enqueueSnackbar('登入成功');
      const profile = data as unknown as IProfile;
      setProfile(() => ({ ...profile }));
      hideLoading();
      if (profile.status === TeacherStatus.UNVERIFIED) {
        if (!isTeacherRecruitmentPath()) {
          navigate(PATH.TEACHER_APPLICATION_CREATE, { replace: true });
        }
      } else {
        isUnauthorizedPath()
          ? navigate(PATH.ROOT, { replace: true })
          : navigate(location.pathname, { replace: true });
      }
    }
  };

  const fetchProfile = async () => {
    if (isTeacherRecruitmentEnabled) {
      handleFetchProfileTeacherRecruitment();
      return;
    }
    handleFetchProfileOfficialTeacher();
  };

  const updateProfile = async (payload: IProfile) => {
    showLoading();
    const { data, status } = await teachers.updateMe(payload);
    if (status === EApiStatus.SUCCESS) {
      setProfile(() => ({ ...(data as unknown as IProfile) }));
      enqueueSnackbar(
        t(
          'services.profileService.updateTeacherProfileSuccess',
          '更新教師資料成功',
        ),
      );
    } else if (status === EApiStatus.FAILURE) {
      enqueueSnackbar(
        t(
          'services.profileService.updateTeacherProfileFailed',
          '更新教師資料失敗',
        ),
        { variant: 'error' },
      );
      setProfile(() => ({ ...(data as unknown as IProfile) }));
    }
    hideLoading();
  };
  const updateInfoTeacherRecruitment = async (
    payload: TeacherRecruitmentDto,
  ) => {
    setInfoTeacherRecruitment(payload);
  };

  useEffect(() => {
    setIsMount(true);
  }, []);

  useEffect(() => {
    if (isMount) {
      if (isLogin) {
        fetchProfile();
      } else {
        hideLoading();
        if (!isUnauthorizedPath()) {
          navigate(PATH.LOGIN, { replace: true });
        }
      }
    }
  }, [isMount, isLogin]);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        updateProfile,
        infoTeacherRecruitment,
        updateInfoTeacherRecruitment,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileService = () =>
  useContext<TProfileContext>(ProfileContext);
